import React, { useState, useEffect, useRef } from 'react';
import Papa from 'papaparse';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import _ from 'lodash';
import Layout from '../../components/layout';
import Seo from '../../components/seo';

const CSVViewerTool = () => {
  // State for CSV data
  const [csvData, setCsvData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [fileName, setFileName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  
  // Table view settings
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  
  // Visualization settings
  const [viewMode, setViewMode] = useState('table');
  const [chartColumn, setChartColumn] = useState('');
  const [chartType, setChartType] = useState('bar');
  
  // For client-side only components
  const [isClient, setIsClient] = useState(false);

  // AI Column Generation
  const [showAIPanel, setShowAIPanel] = useState(false);
  const [aiColumnName, setAIColumnName] = useState('');
  const [aiPromptTemplate, setAIPromptTemplate] = useState('');
  const [aiEndpoint, setAIEndpoint] = useState('http://localhost:11434/api/generate');
  const [aiModel, setAIModel] = useState('llama3');
  const [isGenerating, setIsGenerating] = useState(false);
  const [generationProgress, setGenerationProgress] = useState(0);
  const [generationError, setGenerationError] = useState('');
  const abortControllerRef = useRef(null);

  useEffect(() => {
    setIsClient(true);
  }, []);

  // Handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setIsLoading(true);
      setError('');
      
      // Reset state
      setCsvData([]);
      setColumns([]);
      setPage(0);
      setSortConfig({ key: null, direction: 'asc' });
      
      // Parse CSV
      Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: (results) => {
          if (results.data && results.data.length > 0) {
            setCsvData(results.data);
            setColumns(results.meta.fields || []);
            
            // Set default chart column (first numeric column)
            const numericColumn = results.meta.fields.find(field => 
              typeof results.data[0][field] === 'number'
            );
            setChartColumn(numericColumn || results.meta.fields[0]);
          } else {
            setError('No data found in the CSV file.');
          }
          setIsLoading(false);
        },
        error: (error) => {
          setError(`Error parsing CSV: ${error.message}`);
          setIsLoading(false);
        }
      });
    }
  };

  // Handle sorting
  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Get sorted data
  const getSortedData = () => {
    if (!sortConfig.key) return csvData;
    
    return [...csvData].sort((a, b) => {
      if (a[sortConfig.key] === null || a[sortConfig.key] === undefined) return 1;
      if (b[sortConfig.key] === null || b[sortConfig.key] === undefined) return -1;
      
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      
      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  // Get paginated data
  const getPaginatedData = () => {
    const sorted = getSortedData();
    return sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  // Table render
  const renderTable = () => {
    const sortedAndPaginatedData = getPaginatedData();
    
    return (
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr className="bg-gray-100">
              {columns.map((column, index) => (
                <th 
                  key={index}
                  className="px-4 py-2 text-left text-sm font-medium text-gray-600 uppercase tracking-wider cursor-pointer border-b border-gray-200"
                  onClick={() => requestSort(column)}
                >
                  {column}
                  {sortConfig.key === column && (
                    <span className="ml-1">
                      {sortConfig.direction === 'asc' ? '↑' : '↓'}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedAndPaginatedData.length > 0 ? (
              sortedAndPaginatedData.map((row, rowIndex) => (
                <tr 
                  key={rowIndex}
                  className={rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                >
                  {columns.map((column, colIndex) => (
                    <td key={colIndex} className="px-4 py-2 border-b border-gray-200 text-sm">
                      {row[column] !== null && row[column] !== undefined ? String(row[column]) : ''}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns.length} className="px-4 py-2 text-center text-gray-500">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
        
        {/* Pagination */}
        <div className="flex justify-between items-center mt-4">
          <div>
            <span className="text-sm text-gray-700">
              Showing {page * rowsPerPage + 1} to {Math.min((page + 1) * rowsPerPage, csvData.length)} of {csvData.length} entries
            </span>
          </div>
          <div className="flex space-x-2">
            <button
              onClick={() => setPage(Math.max(0, page - 1))}
              disabled={page === 0}
              className={`px-3 py-1 rounded ${page === 0 ? 'bg-gray-200 text-gray-500' : 'bg-blue-500 text-white'}`}
            >
              Previous
            </button>
            <button
              onClick={() => setPage(Math.min(Math.ceil(csvData.length / rowsPerPage) - 1, page + 1))}
              disabled={page >= Math.ceil(csvData.length / rowsPerPage) - 1}
              className={`px-3 py-1 rounded ${page >= Math.ceil(csvData.length / rowsPerPage) - 1 ? 'bg-gray-200 text-gray-500' : 'bg-blue-500 text-white'}`}
            >
              Next
            </button>
          </div>
          <div>
            <select
              value={rowsPerPage}
              onChange={(e) => {
                setRowsPerPage(Number(e.target.value));
                setPage(0);
              }}
              className="p-1 border border-gray-300 rounded"
            >
              {[5, 10, 25, 50, 100].map(value => (
                <option key={value} value={value}>
                  {value} rows
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  };

  // Chart render
  const renderChart = () => {
    if (!chartColumn || csvData.length === 0) {
      return <div className="text-center text-gray-500 py-10">Select a column to visualize</div>;
    }

    // Get unique labels for X-axis (like categories)
    const xAxisColumn = columns.find(col => col !== chartColumn) || columns[0];
    
    // Format data for chart
    const chartData = csvData.map(row => ({
      name: String(row[xAxisColumn] || ''),
      value: typeof row[chartColumn] === 'number' ? row[chartColumn] : 0
    }));

    return (
      <div className="p-4 h-96">
        <ResponsiveContainer width="100%" height="100%">
          {chartType === 'bar' ? (
            <BarChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" name={chartColumn} fill="#8884d8" />
            </BarChart>
          ) : (
            <LineChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="value" name={chartColumn} stroke="#8884d8" />
            </LineChart>
          )}
        </ResponsiveContainer>
      </div>
    );
  };

  // Stats render
  const renderStats = () => {
    if (csvData.length === 0) return null;
    
    const numericColumns = columns.filter(col => 
      typeof csvData[0][col] === 'number'
    );
    
    if (numericColumns.length === 0) {
      return <div className="text-center text-gray-500 py-4">No numeric columns found for statistics</div>;
    }
    
    return (
      <div className="mt-4 p-4 bg-gray-50 rounded">
        <h3 className="text-lg font-medium mb-3">Summary Statistics</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {numericColumns.map(col => {
            const values = csvData.map(row => row[col]).filter(val => 
              val !== null && val !== undefined && !isNaN(val)
            );
            
            if (values.length === 0) return null;
            
            const sum = values.reduce((acc, val) => acc + val, 0);
            const avg = sum / values.length;
            const min = Math.min(...values);
            const max = Math.max(...values);
            
            return (
              <div key={col} className="bg-white p-3 rounded shadow-sm">
                <h4 className="font-medium text-gray-700">{col}</h4>
                <ul className="text-sm space-y-1 mt-2">
                  <li><span className="font-medium">Count:</span> {values.length}</li>
                  <li><span className="font-medium">Sum:</span> {sum.toFixed(2)}</li>
                  <li><span className="font-medium">Average:</span> {avg.toFixed(2)}</li>
                  <li><span className="font-medium">Min:</span> {min.toFixed(2)}</li>
                  <li><span className="font-medium">Max:</span> {max.toFixed(2)}</li>
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  // Process a template string, replacing {{columnName}} with the actual value
  const processTemplate = (template, rowData) => {
    let processed = template;
    
    // Replace all instances of {{columnName}} with the actual value
    columns.forEach(column => {
      const pattern = new RegExp(`\\{\\{${column}\\}\\}`, 'g');
      const value = rowData[column] !== undefined && rowData[column] !== null 
        ? String(rowData[column]) 
        : '';
      processed = processed.replace(pattern, value);
    });
    
    return processed;
  };

  // Generate a new column using the Ollama API
  const generateAIColumn = async () => {
    if (!aiColumnName.trim()) {
      setGenerationError('Please provide a column name');
      return;
    }
    
    if (!aiPromptTemplate.trim()) {
      setGenerationError('Please provide a prompt template');
      return;
    }
    
    if (columns.includes(aiColumnName)) {
      setGenerationError('Column name already exists. Please choose a different name.');
      return;
    }
    
    try {
      setIsGenerating(true);
      setGenerationError('');
      setGenerationProgress(0);
      
      // Create a copy of the data to modify
      const newData = [...csvData];
      const totalRows = newData.length;
      let hasErrors = false;
      let errorSamples = [];
      
      // Create abort controller for cancellation
      abortControllerRef.current = new AbortController();
      
      // Process each row
      for (let i = 0; i < totalRows; i++) {
        // Check if operation was cancelled
        if (abortControllerRef.current.signal.aborted) {
          throw new Error('Operation cancelled');
        }
        
        const row = newData[i];
        const prompt = processTemplate(aiPromptTemplate, row);
        
        try {
          const response = await fetch(aiEndpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              model: aiModel,
              prompt: prompt,
              stream: false
            }),
            signal: abortControllerRef.current.signal
          });
          
          if (!response.ok) {
            throw new Error(`API request failed with status ${response.status}`);
          }
          
          const data = await response.json();
          const generatedText = data.response ? data.response.trim() : '';
          
          // Add the generated text to the row
          row[aiColumnName] = generatedText;
        } catch (error) {
          hasErrors = true;
          if (error.name === 'AbortError') {
            throw new Error('Operation cancelled');
          }
          
          console.error(`Error generating AI content for row ${i}:`, error);
          
          // Store error sample (up to 3)
          if (errorSamples.length < 3) {
            errorSamples.push({
              rowIndex: i,
              error: error.message
            });
          }
          
          row[aiColumnName] = `Error: ${error.message}`;
        }
        
        // Update progress
        setGenerationProgress(Math.floor(((i + 1) / totalRows) * 100));
      }
      
      if (hasErrors) {
        // If we have errors but completed, show a warning but still update the data
        const errorMessage = isNetworkError(errorSamples[0]?.error) ? 
          `Network error detected. Please ensure Ollama is running at ${aiEndpoint}. Some rows contain errors.` :
          `Generation completed with errors in some rows.`;
          
        setGenerationError(errorMessage);
      }
      
      // Update the data state with the new column
      setCsvData(newData);
      
      // Update columns array to include the new column
      if (!columns.includes(aiColumnName)) {
        setColumns([...columns, aiColumnName]);
      }
      
      // Only hide panel if no network errors occurred at the beginning
      if (!isNetworkError(errorSamples[0]?.error) || errorSamples[0]?.rowIndex > 5) {
        setShowAIPanel(false);
      }
      
      setIsGenerating(false);
    } catch (error) {
      const errorMsg = isNetworkError(error.message) ?
        `Connection error: Please ensure Ollama is running at ${aiEndpoint}. Error: ${error.message}` :
        `Generation failed: ${error.message}`;
      
      setGenerationError(errorMsg);
      setIsGenerating(false);
    }
  };

  // Helper to check if an error is network related
  const isNetworkError = (errorMsg) => {
    if (!errorMsg) return false;
    const networkErrorTerms = [
      'network', 'connect', 'connection', 'fetch', 'failed to fetch', 
      'failed to connect', 'network error', 'econnrefused', 'refused',
      'cors', 'not found', '404', '502', '503', '504', 'timeout'
    ];
    
    errorMsg = errorMsg.toLowerCase();
    return networkErrorTerms.some(term => errorMsg.includes(term));
  };

  // Cancel AI generation
  const cancelAIGeneration = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setIsGenerating(false);
      setGenerationError('Operation cancelled by user');
    }
  };

  // Function to convert CSV data back to a string and download it
  const downloadCSV = () => {
    if (!csvData.length || !columns.length) return;
    
    // Create CSV content
    const csvContent = [
      // Header row
      columns.join(','),
      // Data rows
      ...csvData.map(row => {
        return columns.map(col => {
          // Handle values that might contain commas or quotes
          let value = row[col];
          if (value === null || value === undefined) {
            return '';
          }
          value = String(value);
          // Escape quotes and wrap in quotes if needed
          if (value.includes(',') || value.includes('"') || value.includes('\n')) {
            return `"${value.replace(/"/g, '""')}"`;
          }
          return value;
        }).join(',');
      })
    ].join('\n');
    
    // Create blob and download link
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `enhanced_${fileName || 'data.csv'}`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // AI Modal
  const renderAIModal = () => {
    if (!showAIPanel) return null;
    
    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg shadow-xl p-6 max-w-lg w-full max-h-screen overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">Generate AI Column</h2>
            <button 
              onClick={() => setShowAIPanel(false)}
              disabled={isGenerating}
              className="text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
          </div>
          
          {/* Column name */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              New Column Name
            </label>
            <input
              type="text"
              value={aiColumnName}
              onChange={(e) => setAIColumnName(e.target.value)}
              disabled={isGenerating}
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="e.g., Sentiment"
            />
          </div>
          
          {/* Prompt template */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Prompt Template
            </label>
            <div className="text-xs text-gray-500 mb-1">
              Use &#123;&#123;column_name&#125;&#125; to reference column values. Example: "Analyze the sentiment of: &#123;&#123;comment&#125;&#125;"
            </div>
            <textarea
              value={aiPromptTemplate}
              onChange={(e) => setAIPromptTemplate(e.target.value)}
              disabled={isGenerating}
              className="w-full p-2 border border-gray-300 rounded min-h-32"
              placeholder="Analyze the sentiment of this text: {{text}}"
            />
          </div>
          
          {/* Available columns reference */}
          <div className="mb-4 bg-gray-50 p-3 rounded">
            <h3 className="text-sm font-medium text-gray-700 mb-2">Available Columns</h3>
            <div className="flex flex-wrap gap-2">
              {columns.map(column => (
                <button
                  key={column}
                  onClick={() => setAIPromptTemplate(prev => prev + `{{${column}}}`)}
                  disabled={isGenerating}
                  className="px-2 py-1 bg-blue-100 text-blue-800 text-xs rounded hover:bg-blue-200"
                >
                  {`{{${column}}}`}
                </button>
              ))}
            </div>
          </div>
          
          {/* Model settings */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Ollama Model
            </label>
            <input
              type="text"
              value={aiModel}
              onChange={(e) => setAIModel(e.target.value)}
              disabled={isGenerating}
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="e.g., llama3"
            />
          </div>
          
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Ollama API Endpoint
            </label>
            <input
              type="text"
              value={aiEndpoint}
              onChange={(e) => setAIEndpoint(e.target.value)}
              disabled={isGenerating}
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="http://localhost:11434/api/generate"
            />
          </div>
          
          {/* Progress */}
          {isGenerating && (
            <div className="mb-4">
              <div className="flex justify-between text-sm mb-1">
                <span>Generating...</span>
                <span>{generationProgress}%</span>
              </div>
              <div className="w-full bg-gray-200 rounded h-2">
                <div 
                  className="bg-blue-600 h-2 rounded" 
                  style={{ width: `${generationProgress}%` }}
                ></div>
              </div>
            </div>
          )}
          
          {/* Error message */}
          {generationError && (
            <div className="mb-4 bg-red-100 border-l-4 border-red-500 text-red-700 p-3">
              <p className="font-medium">Error:</p>
              <p>{generationError}</p>
              
              {generationError.toLowerCase().includes('network') || 
               generationError.toLowerCase().includes('connect') || 
               generationError.toLowerCase().includes('fetch') && (
                <div className="mt-2 p-2 bg-red-50 rounded text-sm">
                  <p className="font-medium">Troubleshooting steps:</p>
                  <ol className="list-decimal ml-5 mt-1 space-y-1">
                    <li>Ensure Ollama is installed and running</li>
                    <li>Check the API endpoint is correct (default: http://localhost:11434/api/generate)</li>
                    <li>Verify the model name is correctly spelled and available in your Ollama installation</li>
                    <li>Try running: <code className="bg-gray-100 px-1 rounded">curl -X POST http://localhost:11434/api/generate -d {"{"}"model":"llama3","prompt":"hello"{"}"}</code></li>
                  </ol>
                </div>
              )}
            </div>
          )}
          
          {/* Action buttons */}
          <div className="flex justify-end space-x-3">
            <button
              onClick={() => setShowAIPanel(false)}
              disabled={isGenerating}
              className="px-4 py-2 border border-gray-300 rounded text-gray-700 hover:bg-gray-50"
            >
              Cancel
            </button>
            {isGenerating ? (
              <button
                onClick={cancelAIGeneration}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
              >
                Stop Generation
              </button>
            ) : (
              <button
                onClick={generateAIColumn}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                Generate
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <Seo 
        title="CSV Viewer & Analyzer" 
        description="View, analyze, and visualize CSV files with this free online tool. Sort, filter, and create charts from your data without uploading to any server."
      />
      <div className="max-w-6xl mx-auto p-4">
        <h1 className="text-2xl font-bold mb-6">CSV Viewer & Analyzer</h1>
        
        {/* File upload section */}
        {isClient && (
          <div className="mb-6 p-4 bg-gray-50 rounded-lg border border-gray-200">
            <div className="flex flex-col md:flex-row items-center gap-4">
              <label className="flex-1">
                <div className="bg-white px-4 py-6 rounded-lg border-2 border-dashed border-gray-300 text-center cursor-pointer hover:bg-gray-50 transition">
                  <span className="block text-gray-700 mb-2">
                    {fileName ? `Selected: ${fileName}` : 'Choose CSV file or drop it here'}
                  </span>
                  <span className="inline-block px-4 py-2 bg-blue-500 text-white rounded">Browse Files</span>
                  <input 
                    type="file" 
                    accept=".csv" 
                    onChange={handleFileChange} 
                    className="hidden" 
                  />
                </div>
              </label>
              
              <div className="flex-none space-y-2">
                <div className="flex justify-between items-center">
                  <span className="text-sm font-medium text-gray-700">View Mode:</span>
                  <div className="ml-2">
                    <select 
                      value={viewMode} 
                      onChange={(e) => setViewMode(e.target.value)}
                      className="p-1 border border-gray-300 rounded"
                    >
                      <option value="table">Table</option>
                      <option value="chart">Chart</option>
                    </select>
                  </div>
                </div>
                
                {viewMode === 'chart' && (
                  <>
                    <div className="flex justify-between items-center mt-2">
                      <span className="text-sm font-medium text-gray-700">Chart Column:</span>
                      <div className="ml-2">
                        <select 
                          value={chartColumn} 
                          onChange={(e) => setChartColumn(e.target.value)}
                          className="p-1 border border-gray-300 rounded"
                          disabled={columns.length === 0}
                        >
                          {columns.map((col) => (
                            <option key={col} value={col}>{col}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="flex justify-between items-center mt-2">
                      <span className="text-sm font-medium text-gray-700">Chart Type:</span>
                      <div className="ml-2">
                        <select 
                          value={chartType} 
                          onChange={(e) => setChartType(e.target.value)}
                          className="p-1 border border-gray-300 rounded"
                        >
                          <option value="bar">Bar Chart</option>
                          <option value="line">Line Chart</option>
                        </select>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        
        {/* Loading state */}
        {isLoading && (
          <div className="text-center py-10">
            <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-blue-500 border-r-transparent"></div>
            <p className="mt-2 text-gray-700">Loading CSV data...</p>
          </div>
        )}
        
        {/* Error message */}
        {error && (
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4">
            <p>{error}</p>
          </div>
        )}
        
        {/* CSV Data display */}
        {!isLoading && csvData.length > 0 && (
          <div className="bg-white shadow rounded-lg overflow-hidden">
            {/* View options - table/chart mode */}
            <div className="flex justify-between items-center p-4 border-b">
              <h2 className="text-lg font-medium">
                {fileName} ({csvData.length} rows, {columns.length} columns)
              </h2>
            
              <div className="flex space-x-2">
                {/* Download button */}
                <button
                  onClick={downloadCSV}
                  className="px-3 py-1 bg-green-600 text-white rounded hover:bg-green-700 flex items-center"
                >
                  <span className="mr-1">📥</span> Download CSV
                </button>
                
                {/* AI Column Generation button */}
                <button
                  onClick={() => {
                    console.log('Add AI Column button clicked');
                    setShowAIPanel(true);
                  }}
                  className="px-3 py-1 bg-purple-600 text-white rounded hover:bg-purple-700 flex items-center"
                >
                  <span className="mr-1">✨</span> Add AI Column
                </button>
              </div>
            </div>
            
            {/* Content based on view mode */}
            {viewMode === 'table' ? renderTable() : renderChart()}
            
            {/* Statistics section */}
            {renderStats()}
          </div>
        )}
        
        {/* Instructions when no file is loaded */}
        {!isLoading && csvData.length === 0 && !error && (
          <div className="text-center py-16 bg-white shadow rounded-lg">
            <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">No CSV file loaded</h3>
            <p className="mt-1 text-sm text-gray-500">Upload a CSV file to get started</p>
          </div>
        )}
      </div>
      
      {/* AI Column Generation Modal */}
      {renderAIModal()}
    </Layout>
  );
};

export default CSVViewerTool;
